<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="1000px">

    <template v-slot:activator="{ on, attrs }">

      <v-btn v-if="isToNew" class="ml-6" outlined color="#522daa" v-bind="attrs" v-on="on">
        {{ $i18n.t("misc.new") }}
      </v-btn>

      <v-btn v-if="isToEdit" icon small :color="'blue'" class="mr-2" v-bind="attrs" v-on="on">
        <v-icon > mdi-pencil </v-icon>
      </v-btn>

    </template>

    <v-form ref="form" v-model="valid" lazy-validation>
      
      <v-card class="pt-2 pl-2">
        <v-card-title>
          <span class="text-h5">
            {{ isToEdit ? $t("misc.editing") : $t("misc.creating") }}
            {{$i18n.t("configurationSup.corp")}}
          </span>
        </v-card-title>
      </v-card>
      
      <v-card class="pa-6 pb-2" elevation="0">
          
        <v-row>

          <v-col>
            <v-text-field
            filled
            :hint="$t('misc.sesion')"
            :label="getUserPath + corp.url"
            v-model="corp.url"
            :rules="[rules.required, rules.nameRules, rules.pathRules]"
            maxlength="50"
            color="deep-purple accent-2"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row>

          <v-col md="10">
            <v-text-field
            filled
            :label="$t('misc.name')"
            v-model="corp.name"
            :rules="[rules.required, rules.nameRules]"
            counter
            maxlength="50"
            color="deep-purple accent-2"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-checkbox
            :color="!!menuIconColor ? menuIconColor : ' deep-purple accent-2 '"
            v-model="corp.enabled"
            :label="$t('misc.enabled')"
            ></v-checkbox>
          </v-col>

        </v-row>

        <v-row>

          <v-col>
            <v-text-field
              filled
              v-model="corp.extLen"
              type="number"
              :label="$t('corp.extLen')"
              :rules="[rules.extLenRules]"
              color="deep-purple accent-2"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-row>
              <v-col md="10">
                <v-text-field
                  filled
                  v-model="corp.extMax"
                  :label="$t('corp.extMax')"
                  :rules="[extMaxRules]"
                  color="deep-purple accent-2"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small elevation="0" v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1"> mdi-help </v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('corp.extMax')+": "+ $t('corp.helpExtMax')}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

        <v-row>

          <v-col>
            <v-text-field
            filled
            v-model="corp.maxContacts"
            type="number"
            :label="$t('misc.maxContacts')"
            :rules="[rules.maxContRules]"
            color="deep-purple accent-2"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
            filled
              v-model="corp.queMax"
              type="number"
              :label="$t('corp.queueMax')"
              :rules="[rules.qdeMaxRules]"
              color="deep-purple accent-2"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row>

          <v-col >
            <v-text-field
              filled
              v-model="corp.monPath"
              :disabled="!corp.monEnabled"
              :label="$t('corp.monPath')"
              :rules="[rules.pathRules]"
              color="deep-purple accent-4"
            >
            </v-text-field>
          </v-col>

        </v-row>

        <v-row>

          <v-col>
            <v-checkbox
              :color="!!menuIconColor ? menuIconColor : ' deep-purple accent-2 '"
              v-model="corp.monEnabled"
              :label="$t('corp.monEnabled')"
              @click="pathfolder(corp.name)"
            ></v-checkbox>
          </v-col>

          <v-col>
            <v-checkbox
              :color="!!menuIconColor ? menuIconColor : ' deep-purple accent-2 '"
              v-model="corp.allowCustom"
              :label="$t('corp.allowCustom')"
            ></v-checkbox>
          </v-col>

        </v-row>
         
        <v-divider class="mt-5"></v-divider>

        <v-card-actions >

          <v-btn style="margin: 15px; font-size: 1em;" color="#522daa" text @click="dialog = false">
            <strong>
              {{$t("misc.cancel")}}
            </strong>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn style="margin: 15px; font-size: 1em;" color="#522daa" text @click="validate()">
            <strong>
              {{!isNew ? $t("misc.save") : $t("misc.send")}}
            </strong>
          </v-btn>

        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import Vue from "vue";

import msgCodes from "@/extendedFunctions/msgCodes";

import { mapActions, mapGetters } from "vuex";
import * as action from "@/store/actions";
import i18n from "@/plugins/i18n";
import { Corp } from "@/types/conf";
import Top from "@/components/templates/Top.vue";

export default Vue.extend({
  name: "CorpDetail",
  props: ["corpToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,
    corp: {
      url: "",
      name: "",
      monPath: "",
      extLen: 4,
      extMax: 1,
      queMax: 1,
      maxContacts: 1,
      enabled: true,
      costEnabled: false,
      allowCustom: false,
      allowDiscador: false,
      monEnabled: false,

    },

    rules: {
      required: (v) => !!v || i18n.t("loginView.required"),
      nameRules: (v) => v.match(/^[a-zA-Z0-9_ ]*$/) || i18n.t("rules.alphanum"),
      extLenRules: (v) => (v == 3 || v == 4) || i18n.t("rules.between", { num: 3, num2: 4, }),
      maxContRules: (v) =>  v > 0 && v < 6 || "1 - 5",
      qdeMaxRules: (v) => (v > -1 && v < 100) || i18n.t("rules.between", { num: 0, num2: 99, }),
      pathRules: (v) => (v.match(/^[a-zA-Z0-9-+*.//]*$/) || v == null) || i18n.t("rules.alphanumAndSpecial")
    },
    isNew: false,
  }),

  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),

    getUserPath() {
      return window.location.origin + "/login/";
    },
  },

  components: {
    Top
  },

  methods: {
    
    ...mapActions({
      getCorpList: "crudService/getList",
      newCorp: "crudService/newObject",
      updateCorp: "crudService/updateObject",
      getCorpDetail: "crudService/getDetail",
    }),
      
    extMaxRules(v) {

      if (this.corp.extLen == 3) return (v >= 1 && v <= 799) || i18n.t("rules.between", { num: 1, num2: 799, });

      if (this.corp.extLen == 4) return (v >= 1 && v <= 8999) || i18n.t("rules.between", { num: 1, num2: 8999, });
    },

    corpDetailHandler(corp) {
      this.corp.url = corp.url || "";
      this.corp.name = corp.name;
      this.corp.monPath = corp.monPath;
      this.corp.extLen = corp.extLen;
      this.corp.extMax = corp.extMax;
      this.corp.queMax = corp.queMax;
      this.corp.maxContacts = corp.maxContacts;
      this.corp.enabled = corp.enabled == i18n.t("misc.yes");
      this.corp.costEnabled = corp.costEnabled;
      this.corp.allowCustom = corp.allowCustom;
      this.corp.allowDiscador = corp.allowDiscador;
      this.corp.monEnabled = corp.monEnabled == i18n.t("misc.yes");
    },

    async validate() {

      if (this.$refs.form.validate()) {      
      //if ((this.$refs.form & { validate: () => boolean }).validate()) {
        if(this.corp.monPath?.length<=0) this.corp.monPath = '/' + this.corp.name.replace(/\s/g, '');
        let newCorp = {
          id: null,
          Url: this.corp.url,
          Name: this.corp.name,
          Enabled: this.corp.enabled ? 1 : 0,
          ExtLen: this.corp.extLen,
          ExtMax: this.corp.extMax,
          QueMax: this.corp.queMax,
          MaxContacts: this.corp.maxContacts,
          MonEnabled: this.corp.monEnabled ? 1 : 0,
          MonPath: this.corp.monPath,
          AllowCustom: this.corp.allowCustom,
          AllowDiscador: this.corp.allowDiscador,
          CostEnabled: this.corp.costEnabled,
        };
        let response;
        if (this.isNew) {
          response = await this.newCorp({
            action: action.CORP_CONTROLLER,
            obj: newCorp,
          });
          this.refresh()
        } else {
          newCorp.id = this.corpToUpdate.id;
          response = await this.updateCorp({
            action: action.CORP_CONTROLLER,
            obj: newCorp,
          });
        }
          
        // This set if the form must persist or don't
        this.dialog = response >= 400

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog)
      }
    },

    pathfolder(name){
      this.corp.monPath = '/'+name.replace(/\s/g, "")
    },

    refresh() {
    this.corp = {
      url: "",
      name: "",
      monPath: "",
      extLen: 4,
      extMax: 1,
      queMax: 1,
      maxContacts: 1,
      enabled: true,
      costEnabled: false,
      allowCustom: false,
      allowDiscador: false,
      monEnabled: false
    };
}
    
  },
  
  async mounted() {
    const isNew = this.corpToUpdate == null || this.corpToUpdate == "undefined";
    this.isNew = isNew;
    if (!isNew) {
      this.corpDetailHandler(this.corpToUpdate);
    }
  },
});
</script>
