
import { mapActions, mapGetters } from "vuex";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";

import i18n from "@/plugins/i18n";
import config from "../../../config.js";
import Vue from "vue";
import { Poll, View } from "@/types/conf";
import PollsDetail from "./PollsDetail.vue";
import msgCodes from "@/extendedFunctions/msgCodes";

export default Vue.extend({
  name: "Polls",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 4 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    itemList: [] as Poll[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    selectedItem: null as Poll | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.itemList = await this.getPollList(action.POLL_CONTROLLER);
    for (let i = 0; i < this.itemList.length; i++) {
      this.stateIcons.push("mdi-play");
    }
    this.playingSound.length = this.itemList.length;
    this.actionHandler();
    this.loadingList = false;
  },
  beforeDestroy() {
    this.playingSound.forEach((sound, index) => {
        if (sound.paused === false) {
          sound.pause();
          this.stateIcons[index].sound = "mdi-play"; // Cambia el ícono a 'play'
        }
      }); 
  },
  components: {
    CSV,
    CopyButton,
    Top,
    PollsDetail
},

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getPollList: "crudService/getList",
      deletePoll: "crudService/deleteObject",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          width: "150px",
        },
        {
          text: i18n.t("misc.name"),
          align: "start",
          value: "name",
        },
        {
          text: i18n.t("misc.description"),
          align: "start",
          value: "description",
        },
        {
          text: i18n.t("misc.audio"),
          value: "sound",
          sortable: false,
          width: "105px",
        },
      ];
    },
    async downloadFile(item: Poll) {
      const file = item.sound;
      const url = config.url + "poll/sounds/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.sound;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    setPlay(item: Poll) {
      let index = this.itemList.indexOf(item);
      let icon;
      icon = this.stateIcons[index];
      this.playingSound.forEach((sound, soundIndex) => {
        if (!sound.paused) {
          sound.pause();
          this.stateIcons[soundIndex] = "mdi-play"; // Cambiar todos los íconos a "mdi-play"
        }
      })
      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },
    async playSound(val: Poll) {
      const index = this.itemList.indexOf(val);
      const file = val.sound;
      const url = config.url + "poll/sounds/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound(val: Poll) {
      const index = this.itemList.indexOf(val);
      this.playingSound[index].pause();
      this.reloadList();
    },
    reloadList() {
      let itemListUpdated = [...this.itemList];
      this.itemList = itemListUpdated.filter((row) => row.id);
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.POLLS); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: Poll) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deletePoll({
        action: action.POLL_CONTROLLER,
        id: this.selectedItem?.id,
      });
      
      // This set if the form must persist or don't
      this.dialogDelete = response >= 400;

      this.alertMessage = msgCodes(response, false).alertMessage;
      this.alertType = msgCodes(response, false).alertType;

      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList();
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    addQuestions(item: Poll) {
      this.$router.replace({
        name: "questions",
        params: { questionsId: item.id ? item.id?.toString() : "" },
      });
    },
    
    async refreshList() {
      this.loadingList = true;
      this.itemList = await this.getPollList(action.POLL_CONTROLLER);
      for (let i = 0; i < this.itemList.length; i++) {
        this.stateIcons.push("mdi-play");
      }
      this.playingSound.length = this.itemList.length;
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
