
import { mapActions, mapGetters } from "vuex";
import config from "../../../config.js";

import i18n from "@/plugins/i18n";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import Vue from "vue";
import { View, VoiceMail } from "@/types/conf";
import Top from "@/components/templates/Top.vue";
import msgCodes from "@/extendedFunctions/msgCodes";


export default Vue.extend({
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "", 
    alert2: false,
    alertType2: "",
    voiceMailList: [] as VoiceMail[],
    stateIcons: [] as string[],
    playingSound: [] as HTMLAudioElement[],
    selectedItem: null as VoiceMail | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
      views: "auth/views",
      menuIconColor: "lookAndFeel/menuIconColor",
    }),

    computedVoiceMailList() {
      let voiceMailCache: VoiceMail[] = this.voiceMailList;
      
      voiceMailCache = voiceMailCache.map(el => ({
          ...el,
          origtime: this.dateFormat(el.origtime),
          duration: this.durationFormat(el.duration),
          flag: +el.flag ? i18n.t("misc.listenYes") : i18n.t("misc.listenNo")
        })
      )
      
      return voiceMailCache;
    }
  },

  async mounted() {
    this.refreshList()
  },
  beforeDestroy() {
    this.playingSound.forEach((sound, index) => {
        if (sound.paused === false) {
          sound.pause();
          this.stateIcons[index].sound = "mdi-play"; // Cambia el ícono a 'play'
        }
      }); 
  },
  components: {
    CSV,
    CopyButton,
    Top
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getVoiceMailList: "crudService/getList",
      deleteVoiceMail: "crudService/deleteObject",
    }),

    customSort(items: any[], index: string[], isDesc: boolean[]) {
      return items.sort((a: any, b: any) => {
        if (index[0] == "duration") {
          
          let originalDurationA: any = this.voiceMailList.find((e: any) => this.durationFormat(e.duration) == a.duration);
          let originalDurationB: any = this.voiceMailList.find((e: any) => this.durationFormat(e.duration) == b.duration);
          
          let durationA: any = originalDurationA.duration
          let durationB: any = originalDurationB.duration

          return !isDesc[0] ? durationB - durationA : durationA - durationB;
          
        } else if (index[0] == "origtime") {
          
          let originalOrigtimeA: any = this.voiceMailList.find((e: any) => this.dateFormat(e.origtime) == a.origtime);
          let originalOrigtimeB: any = this.voiceMailList.find((e: any) => this.dateFormat(e.origtime) == b.origtime);
          
          let origtimeA: any = originalOrigtimeA.origtime
          let origtimeB: any = originalOrigtimeB.origtime

          return !isDesc[0] ? origtimeB - origtimeA : origtimeA - origtimeB;

        } else if(typeof a[index[0]] !== 'undefined'){

          let firstItem = a[index[0]].toString().toLowerCase();
          let secondItem = b[index[0]].toString().toLowerCase();

          if (!isDesc[0]) return firstItem.localeCompare(secondItem);
          else return secondItem.localeCompare(firstItem);
        }
      });
    },

    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "0px",
        },
        {
          text: i18n.t("misc.date"),
          value: "origtime",
        },
        {
          text: i18n.t("misc.origin"),
          value: "callerid",
        },
        {
          text: i18n.t("misc.destination"),
          value: "destino",
        },
        {
          text: i18n.t("misc.duration"),
          value: "duration",
        },
        {
          text: i18n.t("misc.status"),
          value: "flag",
        },
        {
          text: i18n.t("misc.audio"),
          align: "left",
          value: "sound",
          sortable: false,
          width: "7rem",
        },
      ];
    },

    dateFormat(date: string) {
      let myDate = new Date(+date * 1000).toLocaleString(this.$i18n.locale);
      return myDate ? myDate.split(",")[0] + " " + myDate.split(",")[1] : ""; 
    },

    durationFormat(time: number) {
      return Math.ceil(time / 60) > 1 ? `${Math.ceil(time / 60)} ${i18n.t("misc.minutes")}` : `${time} ${i18n.t("misc.seconds")}`
    },

    async refreshList() {
      this.loadingList = true;
      this.voiceMailList = await this.getVoiceMailList(
        action.VOICEMAIL_CONTROLLER,
        this.$store.state.auth.user.corpId
      );
      this.actionHandler();

      for (let i = 0; i < this.voiceMailList.length; i++) {
        this.stateIcons.push("mdi-play");
      }
      this.playingSound.length = this.voiceMailList.length;
      this.loadingList = false;
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.VOICE_MAIL);
      
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: VoiceMail) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    setPlay(item: VoiceMail | any, index: number) {
      let icon = this.stateIcons[index];
      this.playingSound.forEach((sound, soundIndex) => {
        if (!sound.paused) {
          sound.pause();
          this.stateIcons[soundIndex] = "mdi-play"; // Cambiar todos los íconos a "mdi-play"
        }
      })
      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item, index);
      } else {
        icon = "mdi-play";
        this.pauseSound(item, index);
      }

      this.stateIcons[index] = icon;
    },

    async playSound(val: VoiceMail, index:number) {
      const file = val.id;
      const url = config.url + "voicemail/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },

    pauseSound(val: VoiceMail, index:number) {
      this.playingSound[index].pause();
      this.reloadList();
    },

    reloadList() {
      let itemListUpdated = [...this.voiceMailList];
      this.voiceMailList = itemListUpdated.filter((row) => row.id);
    },

    async downloadFile(item: VoiceMail) {
      const file = item.id;
      const url = config.url + "voicemail/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.sound;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async deleteItemConfirm() {
      const response = await this.deleteVoiceMail({
        action: action.VOICEMAIL_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();

      // This set if the form must persist or don't
      this.dialog = response >= 400;

      this.alertMessage = msgCodes(response).alertMessage;
      this.alertType = msgCodes(response).alertType;

      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList()
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
});
