

import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import MediaServerDetail from "./MediaServerDetail.vue";

import { mapActions, mapGetters } from "vuex";
import config from "../../../config.js";
import i18n from "@/plugins/i18n";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { MediaServer, View } from "@/types/conf";


export default Vue.extend({
  name: "MediaServer",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 3 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    mediaServerList: [] as MediaServer[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    selectedItem: null as MediaServer | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),

    computedMediaServerList() {
      let mediaServerCache: MediaServer[] = this.mediaServerList;

      mediaServerCache = mediaServerCache.map(el => ({
          ...el,
          enabled: el.enabled ? i18n.t('misc.yes') : i18n.t('misc.no'),
        })
      ) as any;
      
      return mediaServerCache;
    }
  },
  async mounted() {
    this.loadingList = true;
    this.mediaServerList = await this.getMediaServerList(
      action.MEDIA_SERVER_CONTROLLER
    );
    for (let i = 0; i < this.mediaServerList.length; i++) {
      this.stateIcons.push("mdi-play");
    }
    this.playingSound.length = this.mediaServerList.length;
    this.actionHandler();
    this.loadingList = false;
  },
  beforeDestroy() {
    this.playingSound.forEach((sound, index) => {
        if (sound.paused === false) {
          sound.pause();
          this.stateIcons[index].sound = "mdi-play"; // Cambia el ícono a 'play'
        }
      }); 
  },
  components: {
    CSV,
    CopyButton,
    Top,
    MediaServerDetail
},

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getMediaServerList: "crudService/getList",
      deleteMediaServer: "crudService/deleteObject",
    }),
    headers() {
      return [      
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
        { 
          text: i18n.t("misc.num"), 
          value: "number" 
        },
        {
          text: i18n.t("misc.audio"),
          align: "start",
          value: "sound",
        },
        {
          text: i18n.t("misc.enabled"),
          align: "start",
          value: "enabled",
        }
      ];
    },

    async downloadFile(item: MediaServer) {
      const file = item.sound;
      const url = config.url + "mediaserver/sounds/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.sound;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    setPlay(item: MediaServer, index: number) {
      let icon = this.stateIcons[index];
      this.playingSound.forEach((sound, soundIndex) => {
        if (!sound.paused) {
          sound.pause();
          this.stateIcons[soundIndex] = "mdi-play"; // Cambiar todos los íconos a "mdi-play"
        }
      })
      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item, index);
      } else {
        icon = "mdi-play";
        this.pauseSound(item, index);
      }

      this.stateIcons[index] = icon;
    },
    async playSound(val: MediaServer, index:number) {
      const file = val.sound;
      const url = config.url + "mediaserver/sounds/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound(val: MediaServer, index:number) {
      this.playingSound[index].pause();
      this.reloadList();
    },
    reloadList() {
      let itemListUpdated = [...this.mediaServerList];
      this.mediaServerList = itemListUpdated.filter((row) => row.id);
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.MEDIA_SERVERS); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: MediaServer) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteMediaServer({
        action: action.MEDIA_SERVER_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.mediaServerList];
        this.mediaServerList = itemListUpdated.filter(
          (mediaServer) => mediaServer.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.mediaServerList = await this.getMediaServerList(
        action.MEDIA_SERVER_CONTROLLER
      );
      for (let i = 0; i < this.mediaServerList.length; i++) {
        this.stateIcons.push("mdi-play");
      }
      this.playingSound.length = this.mediaServerList.length;
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
