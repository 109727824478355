
import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";

import FileSizeInfo from "@/components/templates/FileSizeInfo.vue";
import Top from "@/components/templates/Top.vue";

import config from "../../../config.js";
import Vue from "vue";
import { MOH, View, User } from "@/types/conf";


export default Vue.extend({
  name: "MOH",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 1,
    computedHeight: screen.width < 600 ? 48 * 7.5 : 48 * 2,

    alertType: "",
    alertMessage: "",
    mohList: [] as MOH[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    selectedItem: null as MOH | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    valid: false,
    loadingList: false,

    obj: {
      sound: null,
    } as MOH,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      user: "auth/user",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.loadList(this.user.corpId);
    this.actionHandler();
    this.loadingList = false;
  },
  beforeDestroy() {
    this.playingSound.forEach((sound, index) => {
        if (sound.paused === false) {
          sound.pause();
          this.stateIcons[index].sound = "mdi-play"; // Cambia el ícono a 'play'
        }
      }); 
  },
  components: {
    FileSizeInfo,
    Top
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getMOHList: "crudService/getList",
      getMOHDetail: "crudService/getDetail",
      deleteMOH: "crudService/deleteObject",
      newMOH: "crudService/newObject",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "40%",
        },
        {
          text: i18n.t("misc.name"),
          align: "start",
          value: "name",
        },
      ];
    },
    async loadList(corpId:MOH) {
      this.mohList = await this.getMOHList(action.MOH_CORP_GET_CONTROLLER+corpId);
      
      for (let i = 0; i < this.mohList.length; i++) {
        this.stateIcons.push("mdi-play");
      }
      this.playingSound.length = this.mohList.length;
    },
    
    async downloadFile(item: MOH) {
      const token = localStorage.getItem("token");
 
      const response = await fetch(`${action.MOH_CORP_CONTROLLER}/${item.name}`, {
        credentials: "same-origin",
        headers: { Authorization: `Bearer ${token}`},
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.name+".wav";
      link.click();
      URL.revokeObjectURL(link.href);
    },

    setPlay(item: MOH) {
      let index = this.mohList.indexOf(item);
      let icon;
      icon = this.stateIcons[index];
      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },

    async playSound(item: MOH) {
      const index = this.mohList.indexOf(item);
      const token = localStorage.getItem("token");
      const result = await fetch(`${action.MOH_CORP_CONTROLLER}/${item.name}`, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });

      const blob = await result.blob();
      if (result) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound(val: MOH) {
      const index = this.mohList.indexOf(val);
      this.playingSound[index].pause();
    },
    reloadList() {
      let itemListUpdated = [...this.mohList];
      this.mohList = itemListUpdated.filter((row) => row.id);
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.MOHCORP);
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },
    editItem(item: MOH) {
      this.navigateTo(item);
    },

    deleteItem(item: MOH) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteMOH({
        action: action.MOH_CORP_CONTROLLER,
        id: this.selectedItem?.name,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.mohList];
        this.mohList = itemListUpdated.filter(
          (moh) => moh.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    async addToList() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = new FormData();
        body.append("Sound", this.obj.sound);

        let response = await this.newMOH({
          action: action.MOH_CORP_CONTROLLER,
          obj: body,
        });

        if (response) {
          this.alertType = "green darken-1";
          this.alertMessage = ((i18n.t("misc.created") as string) +
            i18n.t("misc.defaultSuccess")) as string;
        } else {
          this.alertType = "error";
          this.alertMessage = i18n.t("misc.defaultError") as string;
        }
        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
        this.loadList(this.user.corpId);
      }
    },

    navigateTo(moh: any) {
      if (moh) {
        this.$router.replace({
          name: "moh/update",
          params: { mohToUpdate: moh },
        });
      } else {
        this.$router.replace({
          name: "moh/new",
        });
      }
    },
  },
});
