

import Vue from "vue";

import AlertMessage from "@/components/templates/AlertMessage.vue";
import NavigationDrawer from "@/components/templates/NavigationDrawer/NavigationDrawer.vue";
import Top from "./components/templates/Top.vue";

import { mapActions, mapGetters } from "vuex";
import Panel from "@/plugins/panelHub";

export default Vue.extend({
  name: "App",

  components: {
    AlertMessage,
    NavigationDrawer,
    Top
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      corp: "auth/corp"
    }),
  },
});
