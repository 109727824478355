

import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import FileSizeInfo from "@/components/templates/FileSizeInfo.vue";
import Top from "@/components/templates/Top.vue";

import { mapActions, mapGetters } from "vuex";
import config from "../../../config.js";
import * as action from "@/store/actions";
import i18n from "@/plugins/i18n";
import { Question } from "@/types/conf";
import axios from "axios"
export default Vue.extend({
  name: "QuestionsDetail",
  props: ["questionsId"],
  data: () => ({
    valid: false,
    alertType: "",
    alertMessage: "",
    questionList: [] as Question[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    sortBy: "orderSequencial",
    selectedItem: null as Question | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogPoll: true,
    loadingList: false,
    isEditMode: false,
    question: {
      idPoll: null,
      name: "",
      sound: null,
      orderSequencial: null,
    } as Question,
    exceptionList: [0] as any[],
    orderList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
  }),

  computed: {
    ...mapGetters({
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
      getFile: "fileGetters/getFile",
    }),
    compOrderList(): { text: string | number; disabled: boolean }[] {
      return this.orderList.map((item) => {
        return {
          text: item === 0 ? (i18n.t("misc.option") as string) : item,
          disabled: this.exceptionList.includes(item),
        };
      });
    },
  },
  async mounted() {
    this.loadingList = true;
    await this.loadList();
    for (let i = 0; i < this.questionList.length; i++) {
      this.stateIcons.push("mdi-play");
    }
    this.playingSound.length = this.questionList.length;
    this.loadingList = false;
  },
  beforeDestroy(){
    this.playingSound.forEach((sound, index) => {
        if (sound.paused === false) {
          sound.pause();
          this.stateIcons[index].sound = "mdi-play"; // Cambia el ícono a 'play'
        }
      });
  },
  components: {
    CSV,
    CopyButton,
    FileSizeInfo,
    Top
  },
  methods: {
    ...mapActions({
      newQuestion: "crudService/newObject",
      updateQuestion: "crudService/updateObject",
      deleteQuestion: "crudService/deleteObject",
      getQuestionList: "crudService/getList",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.order"),
          align: "start",
          value: "orderSequencial",
        },
        {
          text: i18n.t("misc.description"),
          align: "start",
          value: "name",
        },
        {
          text: i18n.t("misc.audio"),
          align: "start",
          value: "sound",
        },
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
      ];
    },
    nameRules: (v: string) =>
      v.match(/^[a-zA-Z0-9_ ]*$/) || i18n.t("rules.alphanum"),

    async loadList() {
      const arr = await this.getQuestionList(action.QUESTION_CONTROLLER);
      this.questionList = arr.filter(
        (item: Question) => item.idPoll == this.questionsId
      );
      const arrEx = this.questionList.map((item) => item.orderSequencial);
      this.exceptionList = [...this.exceptionList, ...arrEx];
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body = new FormData();
        body.append("IdPoll", this.questionsId);
        body.append("Name", this.question.name);
        body.append("Sound", this.question.sound);
        !!this.question.orderSequencial
          ? body.append(
              "OrderSequencial",
              this.question.orderSequencial?.toString()
            )
          : null;

        let response;
        if(!this.isEditMode){
            response = await this.newQuestion({
            action: action.QUESTION_CONTROLLER,
            obj: body,
          });

          if (response) {
            this.alertMessage = ((i18n.t("misc.created") as string) +
              i18n.t("misc.defaultSuccess")) as string;
            this.alertType = "green darken-1";
            this.question = {
              idPoll: null,
              name: "",
              sound: null,
              orderSequencial: null,
            };
            //reload list
            this.loadList();
            this.stateIcons.push("mdi-play");
            this.playingSound.length = this.questionList.length;
          } else {
            this.alertMessage = i18n.t("misc.defaultError") as string;
            this.alertType = "error";
          }

          // This set if the form must persist or don't
          this.dialog = response >= 400

          this.$store.dispatch("snackbar/showSnack", {
            message: this.alertMessage,
            color: this.alertType,
            timeout: 3000,
          });

          this.$emit("refreshList", this.dialog)
        }
        else{
          body.append("id", this.question.id)
          response = await this.updateQuestion({
            action: action.QUESTION_CONTROLLER,
            obj: body,
          })
          if (response < 400) {
            this.alertMessage = ((i18n.t("misc.updated") as string) +
              i18n.t("misc.defaultSuccess")) as string;
            this.alertType = "green darken-1";
            this.loadList();
            this.stateIcons.push("mdi-play");
            this.playingSound.length = this.questionList.length;
          } else {
            this.alertMessage = i18n.t("misc.defaultError") as string;
            this.alertType = "error";
          }

          // This set if the form must persist or don't
          this.dialog = response >= 400

          this.$store.dispatch("snackbar/showSnack", {
            message: this.alertMessage,
            color: this.alertType,
            timeout: 3000,
          });

          this.$emit("refreshList", this.dialog)
        }
      }  
    },
    goBack() {
      this.$router.replace({
        name: "polls",
      });
    },

    //#region sound controls
    async downloadFile(item: Question) {
      const file = item.sound;
      const url = config.url + "question/getsound/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.sound;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    setPlay(item: Question) {
      let index = this.questionList.indexOf(item);
      let icon;
      icon = this.stateIcons[index];
      this.playingSound.forEach((sound, soundIndex) => {
        if (!sound.paused) {
          sound.pause();
          this.stateIcons[soundIndex] = "mdi-play"; // Cambiar todos los íconos a "mdi-play"
        }
      })
      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },
    async playSound(val: Question) {
      const index = this.questionList.indexOf(val);
      const file = val.sound;
      const url = config.url + "question/getsound/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound(val: Question) {
      const index = this.questionList.indexOf(val);
      this.playingSound[index].pause();
      this.reloadList();
    },
    reloadList() {
      let itemListUpdated = [...this.questionList];
      this.questionList = itemListUpdated.filter((question) => question.id);
    },
    //#endregion

    deleteItem(item: Question) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteQuestion({
        action: action.QUESTION_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.questionList];
        this.questionList = itemListUpdated.filter(
          (question) => question.id != this.selectedItem?.id
        );
        this.exceptionList.splice(
          this.exceptionList.indexOf(this.selectedItem?.orderSequencial),
          1
        );
        this.loadList();
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },
    async editItem(item: Question){
      this.isEditMode = true;
      this.question = { ...item };
      const token = localStorage.getItem('token');
      var response = await axios.get(config.url + "question/getsound/" + item.sound,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
      });
      const file = new File([response.data], item.sound, { type: 'audio/wav' });
      this.question.sound = file;
      this.dialog = true;
    },
    openForm(){
      this.isEditMode = false;
      this.question = { name: '', sound: null, orderSequencial: null };
      this.dialog = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
});
